import { BronsonDates } from '@dh/bronson-react';
import jwt_decode from 'jwt-decode';

import {
    PayloadTokenData,
    PayloadTokenValues,
} from '../views/PrivacyPolicies/privacy-policies.type';

/**
 * Decodes jwt
 * @param code jwt token
 * @returns decoded token
 */
export const decodeToken = (code: string): PayloadTokenData | null => {
    try {
        const data: PayloadTokenValues = jwt_decode(code);
        return {
            requestId: data.idSolicitud,
            petitionId: data.idPeticion,
            expirationDate: data.vigencia,
        };
    } catch (e) {
        return null;
    }
};

/**
 * It validates the expiration token date
 * @param tokenExpirationDate token timestamp
 * @returns boolean
 */
export const validateExpirationDateToken = (tokenExpirationDate: string): boolean => {
    if (BronsonDates.dateAfterNow(tokenExpirationDate)) {
        return true;
    } else {
        return false;
    }
};
