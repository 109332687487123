/* eslint-disable max-len */
export const privacyPoliciesLabels = {
    hero: {
        title: 'Aviso de Privacidad',
        subtitle:
            'Volkswagen Insurance Brokers, Agente de Seguros y de Fianzas, S.A. de C.V.',
    },
    button: {
        accept: 'Enviar',
        expired: 'Link expirado',
        signed: 'Firmado',
    },
    notification: {
        accept: 'Firmas exitosas, para continuar con el proceso ponte en contacto con tu concesionario.',
        expired:
            'La liga ha expirado, ponte en contacto con tu concesionario para realizar el proceso nuevamente y generar una nueva liga.',
        signed: 'Ya se encuentran firmados los documentos, para continuar con el proceso ponte en contacto con tu concesionario.',
    },
    error: {
        accept: 'Error al intentar aceptar los documentos: [error]',
        validate: 'Error al validar el token: [error]',
    },
    initialText:
        'Te informamos que hemos recibido tu confirmación de emisión de la cotización número [requestId]. Para continuar con el proceso de aseguramiento de tu auto, te invitamos a leer y aceptar nuestro Aviso de Privacidad.',
    accordionTitle: 'TRATAMIENTO DE DATOS PERSONALES - AVISO DE PRIVACIDAD SIMPLIFICADO',
    note: 'Para continuar con el proceso de emisión de su póliza es necesario que acepte nuestro Aviso de Privacidad.',
    subTitle:
        'Estamos listos para continuar con la activación de tu Paquete de servicio [commercialProductName] del Programa de Beneficios de Mantenimiento. Para ello es necesario que leas y aceptes nuestros [pages] al pie de ésta página.',
};
