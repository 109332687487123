import React from 'react';

import { HeroProps } from './hero.type';

/**
 * Hero component
 * @param props
 */
export const Hero: React.FC<HeroProps> = ({
    title,
    description,
    iconClass,
    heroImage,
}) => {
    if (heroImage) {
        return (
            <div id='hero-dealer-online' className='c-hero-teaser  c-hero-teaser--auto'>
                <img
                    src={heroImage}
                    sizes='50vw'
                    className='c-hero-teaser__image'
                    alt=''
                />
                <div id='js-hero-teaser-content' className='c-hero-teaser__content'>
                    <div className='c-hero-teaser__content-inner'>
                        <header className='c-hero-teaser__heading'>
                            <div className='c-hero-teaser__title'>
                                <h1>
                                    <span className='c-hero-teaser__title-row'>
                                        {title}
                                    </span>
                                </h1>
                            </div>
                            {(description || iconClass) && (
                                <div className='c-hero-teaser__subtitle c-form-heading__title u-pv-xxsmall'>
                                    <i
                                        className={`c-form-heading__icon  c-icon  c-icon--[${iconClass}]`}
                                        aria-hidden='true'
                                        role='img'
                                    ></i>
                                    <span>{description}</span>
                                </div>
                            )}
                        </header>
                    </div>
                </div>
            </div>
        );
    } else {
        return (
            <div id='hero-dealer-online' className='c-hero-teaser  c-hero-teaser--auto'>
                <div className='c-hero-teaser__content'>
                    <header className='c-hero-teaser__heading'>
                        <div className='c-hero-teaser__title'>
                            <h1>
                                <span className='c-hero-teaser__title-row'>{title}</span>
                            </h1>
                        </div>
                        {(description || iconClass) && (
                            <div className='c-hero-teaser__subtitle c-form-heading__title u-pv-xxsmall'>
                                <i
                                    className={`c-form-heading__icon  c-icon  c-icon--[${iconClass}]`}
                                    aria-hidden='true'
                                    role='img'
                                ></i>
                                <span>{description}</span>
                            </div>
                        )}
                    </header>
                </div>
            </div>
        );
    }
};
