import { useKeycloak } from '@react-keycloak/web';

import { BronsonApp } from '../components/BronsonApp';
import ErrorPage from '../views/Error';
import FooterLink from '../views/Footer';
import { FooterPages } from '../views/Footer/footer.enum';
import { insuranceRoles } from '../config';
import Login from '../views/login';
import { PortalRoutes } from './routes.config';
import PrivacyPolicies from '../views/PrivacyPolicies';
import { PrivateRoute } from './utils';

import { BronsonSpinner, BronsonSpinnerProps } from '@dh/bronson-react';
import { forbiddenLabels, notFoundLabels } from '../views/Error/error.labels';
import { getUserRole, UserDataInterface } from '@dh/keycloak-auth';
import { Route, HashRouter as Router, Routes } from 'react-router-dom';
import { useEffect, useState } from 'react';

export const AppRouter = () => {
    const { keycloak, initialized } = useKeycloak();
    // keycloak token
    const userName = keycloak?.tokenParsed?.sub;
    const [loading, setLoading] = useState(true);

    const [userData, setUserData] = useState<UserDataInterface>();

    useEffect(() => {
        if (initialized) {
            const role = getUserRole(keycloak, insuranceRoles);
            if (userName && !keycloak?.authenticated) {
                setLoading(false);
                window.location.href = '#/login';
            } else if (userName && keycloak?.authenticated && !userData) {
                setUserData({ role, id: userName });
                setLoading(false);
            } else {
                setLoading(false);
            }
        }
    }, [initialized, userName, keycloak, loading, userData]);

    if (initialized && !loading) {
        return (
            <Router basename='/'>
                <BronsonApp userData={userData} loading={loading}>
                    <Routes>
                        {PortalRoutes.map((elem, index) => (
                            <Route
                                key={index}
                                path={elem.path}
                                element={
                                    <PrivateRoute
                                        path={elem.path}
                                        component={elem.component}
                                        allowedRoles={
                                            elem.allowedRoles
                                                ? elem.allowedRoles
                                                : undefined
                                        }
                                    />
                                }
                            />
                        ))}
                        <Route path='/privacy-policy' element={<PrivacyPolicies />} />
                        <Route
                            path='/terms-and-conditions'
                            element={<FooterLink page={FooterPages.GENERAL_TERMS} />}
                        />
                        <Route
                            path='/client/terms-and-conditions'
                            element={<FooterLink page={FooterPages.CLIENT_TERMS} />}
                        />
                        <Route
                            path='/forbidden'
                            element={<ErrorPage {...forbiddenLabels} />}
                        />
                        <Route path='/login' element={<Login />} />
                        <Route path='*' element={<ErrorPage {...notFoundLabels} />} />
                    </Routes>
                </BronsonApp>
            </Router>
        );
    } else {
        const spinnerConfig: BronsonSpinnerProps = {
            showSpinner: true,
            spinnerWrapperClassModifier: 'c-spinner--text',
            showSpinnerText: true,
            spinnerText: 'Estamos configurando tu espacio de trabajo',
        };
        return <BronsonSpinner config={spinnerConfig}></BronsonSpinner>;
    }
};
