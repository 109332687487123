import { getUserRole } from '@dh/keycloak-auth';
import { useKeycloak } from '@react-keycloak/web';
import { Navigate, useLocation } from 'react-router-dom';

import { insuranceRoles } from '../config';
import { RouteDefinition } from './routes.type';

export const PrivateRoute = ({
    component: Component,
    allowedRoles: roles,
}: RouteDefinition) => {
    const { keycloak } = useKeycloak();
    const rol = getUserRole(keycloak, insuranceRoles) || '';
    const location = useLocation();

    if (keycloak.authenticated) {
        if (rol && roles) {
            if (roles.includes(rol)) {
                return <Component />;
            } else {
                return (
                    <Navigate
                        to={{ pathname: '/forbidden' }}
                        state={{ from: location }}
                    />
                );
            }
        } else return <Component />;
    } else {
        return <Navigate to={{ pathname: '/login' }} state={{ from: location }} />;
    }
};
