import { Link } from 'react-router-dom';
import { useKeycloak } from '@react-keycloak/web';
import {
    BronsonFooter,
    BronsonFooterProps,
    BronsonMegaMenu,
    BronsonNavigationLink,
    BronsonSpinner,
    BronsonSpinnerProps,
} from '@dh/bronson-react';
import React, { useState } from 'react';

import { BronsonAppLabels } from './bronson-app.label';
import { PortalRoutes } from '../../routes/routes.config';

export const BronsonApp: React.FC<{
    userData?;
    loading?: boolean;
}> = (props) => {
    // Keycloak instance with current status
    const keycloak = useKeycloak();
    const userRole: string = props.userData?.role || '';
    // Description of user
    const user: { given_name: string } = { given_name: '' };
    // Menu state
    const [logoutMenuOpen, setLogoutMenuOpen] = useState(false);
    // Complements user variable with complete keycloak token
    Object.assign(user, keycloak.keycloak?.idTokenParsed);

    /*
     * +-+-+-+-+-+-+-+-+
     * |H|a|n|d|l|e|r|s|
     * +-+-+-+-+-+-+-+-+
     */
    /**
     * Spinner configuration definition
     */
    const spinnerConfig: BronsonSpinnerProps = {
        showSpinner: true,
        spinnerWrapperClassModifier: 'c-spinner--text',
        showSpinnerText: true,
        spinnerText: 'Estamos configurando tu espacio de trabajo',
    };

    /**
     * This method is a generic generator for menu items
     * @param iconVar icon variable
     * @param menuStatus boolean with the current status of menu
     * @param menuController function to handle state
     * @returns menu icon with controller
     */
    const menuControl = (
        id: string,
        iconVar: string,
        menuStatus: boolean,
        menuController: () => void,
        menuLabel?: JSX.Element,
    ): JSX.Element => {
        return (
            <button
                id={id}
                className={`c-page-head-nav__link ${menuStatus ? 'is-open' : ''}`}
                onClick={menuController}
            >
                <span className='c-page-head-nav__link-text u-hide@s'>{menuLabel}</span>
                <i
                    className={`c-icon c-icon--small c-icon--[${iconVar}] c-page-head-nav__link-icon`}
                ></i>
            </button>
        );
    };

    /**
     * Close logout navbar menu
     */
    const closeLogoutMenu = () => {
        setLogoutMenuOpen(!logoutMenuOpen);
    };

    /**
     * Get Footer configurations for links
     */
    const getFooter = (): BronsonFooterProps => {
        const links = !window.location.href.includes('/#/terms-and-conditions')
            ? [
                  {
                      option: 'Términos y Condiciones',
                      href: '#/client/terms-and-conditions',
                      target: '_blank',
                  },
                  {
                      option: 'Aviso de privacidad VW Insurance Broker',
                      href: 'https://www.vwfs.mx/vwib/aviso-de-privacidad/clientes-prospectos.html',
                      target: '_blank',
                  },
                  {
                      option: 'Politica de cookies',
                      href: 'https://www.vwfs.mx/page/cookies.html',
                      target: '_blank',
                  },
              ]
            : [];
        return {
            copyRight: '© Volkswagen Financial Services MX 2023',
            note:
                '* "Volkswagen Financial Services, The Key to Mobility", ' +
                'significa en español "Volkswagen Servicios Financieros, La Llave de la Movilidad".',
            links,
        };
    };

    /**
     * Get Route definition of the home page to display Dashboard link
     */
    const getHomePage = () => {
        const homePage = PortalRoutes.filter((elem) => elem.path === '/')[0];
        return <BronsonNavigationLink key={0} route={homePage} />;
    };

    /**
     * User label menu
     */
    const userLabel = <span className='u-m-xsmall'>{user.given_name}</span>;
    /**
     * Menu label profile
     */
    const menuLabelProfile = `ROL - ${userRole}`;
    const showHeaderLink = () => {
        return (
            <div>
                <Link id='app-home-control' to='/'>
                    <img
                        className='c-brand-logo c-brand-logo--large-screen u-p-none'
                        src={
                            'https://cdn.bronson.vwfs.tools/bluelabel/v/8.0.0/img/logo.svg'
                        }
                        alt='logo'
                    />
                </Link>
            </div>
        );
    };

    /*
     * +-+-+-+-+ +-+-+-+-+-+-+
     * |M|a|i|n| |R|e|n|d|e|r|
     * +-+-+-+-+ +-+-+-+-+-+-+
     */

    if (props.loading) {
        return (
            <div>
                <BronsonSpinner config={spinnerConfig}></BronsonSpinner>
                {/* Even if the component is loading props.children should be render to allow redirection functionality, 
                    for example on log out */}
                <div className='u-hide'>{props.children}</div>
            </div>
        );
    } else {
        return (
            <div style={{ overflow: 'hidden' }}>
                {/* Site Header */}
                <header className='c-header__wrapper'>
                    <div id='app-navigation-bar' className='c-header c-page-head'>
                        <div className='o-page-wrap'>
                            <div className='c-page-head__inner'>
                                {showHeaderLink()}
                                {keycloak.keycloak?.authenticated && (
                                    <ul className='c-page-head-nav'>
                                        {props.userData?.role && getHomePage()}
                                        <div
                                            className='c-page-head-nav__item'
                                            style={{ marginLeft: 'auto' }}
                                        >
                                            <li
                                                className='c-page-head-nav__item'
                                                id='user-menu'
                                            >
                                                {menuControl(
                                                    'user-menu-control',
                                                    'control-user',
                                                    logoutMenuOpen,
                                                    closeLogoutMenu,
                                                    userLabel,
                                                )}
                                            </li>
                                        </div>
                                    </ul>
                                )}
                            </div>
                            <BronsonMegaMenu
                                routes={[
                                    {
                                        label: BronsonAppLabels.logOut,
                                        img: 'control-logout',
                                        onClick: () => {
                                            keycloak.keycloak?.logout();
                                        },
                                    },
                                ]}
                                menuLabel={menuLabelProfile}
                                disabled={!logoutMenuOpen}
                                onCloseMenu={closeLogoutMenu}
                            />
                        </div>
                    </div>
                </header>

                {/* App Wrapped Content */}
                <main className='o-main'>{props.children}</main>

                {/* App Footer */}
                <BronsonFooter {...getFooter()} />
            </div>
        );
    }
};
