import { Navigate } from 'react-router-dom';
import { useEffect } from 'react';
import { useKeycloak } from '@react-keycloak/web';

const Login = () => {
    const { keycloak } = useKeycloak();

    // TODO: capture real `from` from router
    const { from } = { from: { pathname: '/' } };

    useEffect(() => {
        if (keycloak && !keycloak.authenticated) {
            keycloak.login();
        }
    });

    if (keycloak && keycloak.authenticated) return <Navigate to={from} />;

    return <div>redirecting</div>;
};

export default Login;
