import { alondraCallbackUrl } from '../../config';
import { AppService } from '../../App.service';
import { BronsonSpinner } from '@dh/bronson-react';
import { Hero } from '../../components/Hero';
import heroImage from '../../assets/img/hero/register-dealer.jpg';
import { homeLabels } from './home.labels';
import { useEffect } from 'react';
import { useKeycloak } from '@react-keycloak/web';

const Home = () => {
    const { keycloak, initialized } = useKeycloak();
    // keycloak token
    const userName = keycloak?.tokenParsed?.sub;

    useEffect(() => {
        if (initialized && userName) {
            const appService = new AppService(keycloak);
            appService
                .setAttribute(userName)
                .then(() => {
                    //REDIRECT TO THE PAGE OF ALONDRA
                    window.location.href = alondraCallbackUrl;
                })
                .catch(() => {});
        }
    }, [initialized, keycloak, userName]);

    return (
        <div>
            <Hero
                title={homeLabels.heroTitle}
                description={homeLabels.heroDescription}
                iconClass={homeLabels.heroIconClass}
                heroImage={heroImage}
            />
            <main className='o-main' style={{ height: '200px' }}>
                <section>
                    <BronsonSpinner
                        config={{
                            spinnerWrapperClassModifier: 'c-spinner--center u-m-large',
                            showSpinner: true,
                        }}
                    />
                </section>
            </main>
        </div>
    );
};

export default Home;
