import { ErrorPageValues } from './error.interface';
import heroImage from '../../assets/img/hero/forbidden.jpg';

/**
 * Generic error page.
 * This method return an JSX.Element with a error view created, only you need to
 * put an errorCode, errorCodeDescription, message, messageComplement as variables.
 * @param ErrorPageValues
 * @returns {JSX.Element}
 */
const ErrorPage = ({
    errorCode,
    errorCodeDescription,
    message,
    messageComplement,
}: ErrorPageValues): JSX.Element => {
    return (
        <div>
            <div className='c-hero-teaser c-hero-slider c-hero-teaser--auto'>
                <img
                    src={heroImage}
                    sizes='100vw'
                    className='c-hero-teaser__image'
                    alt=''
                />
                <div id='js-hero-teaser-content' className='c-hero-teaser__content'>
                    <div className='o-page-wrap'>
                        <header className='c-hero-teaser__heading'>
                            <div className='c-hero-teaser__title'>
                                <h1>
                                    <span className='c-hero-teaser__title-row'>
                                        {errorCode} <br />
                                        {` ${errorCodeDescription}`}
                                    </span>
                                </h1>
                            </div>
                            {message && (
                                <div className='c-hero-teaser__subtitle'>{message}</div>
                            )}
                            {messageComplement && (
                                <div className='c-hero-teaser__subtitle'>
                                    {messageComplement}
                                </div>
                            )}
                        </header>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ErrorPage;
